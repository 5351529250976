<script>
import Footer from '@/views/layout/footer'
import Header from '@/views/layout/header'

export default {
  components: {Footer, Header}
}
</script>

<template>
  <div>
    <Header/>
    
    <section class="section contact-page">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="team-member animated fadeInDown wow" data-wow-delay=".3s">
              <img src="@/assets/images/profil.jpg" alt="team-member" class="img-fluid rounded-circle"/>
            </div>
          </div>
          <div class="col-lg-1">

          </div>
          <div class="col-lg-7">
            <h1>Peter Stræde</h1>
            <p class="text-muted">Software developer & skydiver</p>

            <span>As a software developer I do most of these funny words:</span>
            <ul class="list-unstyled">
              <li>Cloud: Azure & AWS</li>
              <li>IasC: Terraform & HCL</li>
              <li>Code: C#, dotnet Core, JS/node/Vue, </li>
              <li>Data: SQL, MongoDb, Parquet, DynamoDb, CosmosDb</li>
              <li>Log/monitor: ElK, Datadog, Jaeger, Prometheus</li>
              <li>OS: Windows, Linux, OSX</li>
              <li>Agile: Scrum, KANBAN, SAFE</li>
              <li>Other: Kubernetes, Docker, Git/Github/Action, Swagger/OpenAPI 3.0</li>
            </ul>

          <span>As a skydiver:</span>
          <ul class="list-unstyled">
            <li>First jump: 3. juni 2007</li>
            <li>Jump count: 3.000+</li>
            <li>Ratings: Instructor, AFF</li>
            <li>Tunnel: 400+ timer</li>
          </ul>
          </div>


        </div>
      </div>
    </section>

    <Footer/>
  </div>
</template>
