<template>
  <footer class="section bg-gray footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <h5>Contact</h5>
          <address>
            Ravnskovgyden 9<br/>
            5466 Asperup<br/>
            P: <a href="tel:+45 20 24 64 84">+45 20 24 64 84</a><br/>
            E: <a href="mailto:peter@headdown.dk">peter@headdown.dk</a><br />
            CVR: 37892238
          </address>
        </div>
        <div class="col-lg-3">
        </div>
        <div class="col-lg-3">
        </div>
        <div class="col-lg-3">
          <h5>Social</h5>
          <ul class="list-unstyled">
            <li><a href="https://www.facebook.com/peter.straede"> <i class="zmdi zmdi-facebook"></i> Facebook</a></li>
            <li><a href="https://www.instagram.com/peterstraede/"> <i class="zmdi zmdi-instagram"></i> Instagram</a></li>
            <li><a href="https://www.linkedin.com/in/peterstraede/"><i class="zmdi zmdi-linkedin"></i> Linkedin</a></li>
          </ul>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="footer-alt">
            <div class="float-left pull-none">
              <a class="navbar-brand logo" href="/#"> <i class="zmdi zmdi-navigation"></i> <span>headdown.dk</span> </a>
            </div>
            <div class="float-right pull-none">
              <p class="pull-right text-muted mb-0">2021 © headdown.dk</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
